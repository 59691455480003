import React from "react"
import { Container, Card } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Doctolib from "../components/doctolib"
import "../styles/global.css"

const Blog = ({ data }) => (
  <Layout>
    <Seo title={data.site.siteMetadata.title} />

    <Container className="container">
      <div style={{ position: "relative" }}>
        <Img
          fixed={data.file.childImageSharp.fixed}
          style={{ maxHeight: 300, width: "100%", objectFit: "cover" }}
        />
        <div className="header-doctolib">{<Doctolib />}</div>
      </div>
      <h1>Tous mes conseils</h1>
      <div className="container-wrap">
        {/* je boucle sur les markdown avec condition d'affichage type === blog */}
        {data.allMarkdownRemark.edges.map(({ node }) =>
          node.frontmatter.type === "blog" ? (
            <Card key={node.id} style={{ width: "18rem" }}>
              <Img fluid={node.frontmatter.image.childImageSharp.fluid} />

              <Card.Body>
                <Card.Title>{node.frontmatter.title}</Card.Title>
                <Card.Text>{node.frontmatter.description}</Card.Text>

                <Link
                  to={node.frontmatter.slug}
                  style={{
                    top: "85%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    color: "#3482C2",
                    fontSize: "1em",
                  }}
                >
                  En savoir plus
                </Link>
              </Card.Body>
            </Card>
          ) : null
        )}
      </div>
    </Container>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "10vw",
        paddingBottom: "10vw",
      }}
    >
      {<Doctolib />}
    </div>
  </Layout>
)

export default Blog

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "mains_ostheopate.jpg" }) {
      childImageSharp {
        fixed(width: 1920, height: 1280) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            slug
            description
            type
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
